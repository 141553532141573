<template>
    <div class="container">
		<!-- 设置邮箱 -->
		<van-nav-bar
		    :title="email?$t('Uset.updateEmail'):$t('Uset.SetEmail')"
		    left-arrow
		    @click-left="onClickLeft"
		    safe-area-inset-top
		    fixed
		    z-index="9999"
		/>
		<div class="content g_safe_top">
			<div class="layoutBox bgWhite body1Style">
                <div v-if="email" class="email_old caption mainFontColor">
                    {{$t('system.codeHasSend')}}
                    <span class="phone mainFontLightColor">{{email}}</span>
                </div>
                <div v-else class="email_old caption mainFontColor">
                    {{$t('system.codeHasSend')}}
                    <span class="phone mainFontLightColor">+{{nation}} {{phone}}</span>
                </div>

                <form-list>
                    <div class="bd" slot="contslot">
                        <input type="number" class="inp" v-model="inputCode" :placeholder="$t('login.PleaseEnterVerificationCode')" />
                        <div class="button" @click="onSendCode">
                            <span v-if="codeSending" class="mainColor1">{{ theTime }}S</span>
                            <span v-else class="mainColor1">{{ $t('sPhone.getCode') }}</span>
                        </div>
                    </div>
                </form-list>
                <br>
				<form-list :label="email?$t('system.qingshuruyouxiang_new'):$t('system.qingshuruyouxiang')">
					<div class="bd" slot="contslot">
						<input class="inp" v-model="emailcode" :placeholder="$t('system.qingshuruyouxiangzhanghao')" />
					</div>
				</form-list>
				
				<div class="comBtnStyle" @click="bindEmail" style="margin-top: 6rem;">{{ $t('system.quedingbangding') }}</div>
			</div>
		</div>
		
    </div>
</template>

<script>
	import formList from '../../components/formList';
    export default {
        name: "setThePassword",
        components: {
			formList,
		},
        data() {
            return {
                value: "",
                phone: "", //  用户手机号
                nation: "", // 国家区号
                // popupShow: false,
                // popupShow2: false,
                // popupShow3: false,
                showKeyboard: false,
                exchange_name: "",
                emailcode: "",
                inputCode: "",
                exchange_id: 0,
                article_id: 0,
                password: "", //交易密码
                password2: "",
                count: 0,
                list2: {},
                flag: false,
                ifhave: false,
                theTime: 60,
                vcode_method: "",
                show: false,
                paypwd: "",
                codeSending: false,
                paypwd2: "",

				email:'',
                vcode_method:'',
            };
        },
        created() {
            this.exchange_name = this.$route.query.exchange_name;
            this.exchange_id = this.$route.query.exchange_id;
            // this.getKey();
            this.initData();
            // this.getKey();
        },

        methods: {
            initData() {
                this.$post2("user/api/index/getMetaInfo", {})
                    .then((res) => {
                        this.email=res.email
                        this.vcode_method=res.vcode_method
                        this.phone = res.phone;
                        this.nation = res.nation;
                    })
                    .catch((e) => {
                        this.$toast.fail(e);
                    });
            },
            bindEmail() {
                if (!this.check()) {
                    return;
                }
                this.setNewEmail();
            },
			// 绑定邮箱
			setNewEmail() {
			    this.$post2("/User/Api/index/setNewEmail", {
			        email: this.emailcode,
					// phone: this.phone,
			        code: this.inputCode,
			        sid: this.sid,
                    type:this.email?2:1,//1-绑定邮箱 2-修改邮箱
			    }).then((res) => {
			        // console.log(res);
                    var userInfo = JSON.parse(localStorage.userInfo);
                    userInfo['email'] = this.emailcode;
                    // if(this.vcode_method=='email'){
                    //     userInfo['username'] = this.emailcode;
                    // }
                    localStorage.userInfo = JSON.stringify(userInfo);
			        this.$toast(this.$t("system.bindEmailSuccess"));
			        this.$router.go(-1);
			    });
			},

            // 获取验证码
            onSendCode() {
                if (this.codeSending) {
                    return;
                }
                this.theTime = 60;
                this.codeSending = true;
                this.doCountDown();
                this.$post2('/security/user/authsmscode', {
                    op: "opsecret",
                }).then((res) => {
                    // console.log(res);
                    this.sid = res.sid;
                });
				// this.$post2('/security/user/authsmscode', {
				// 	phone: '15037915292',
				// 	nation: '86',
				// 	op: 'opsecret',
				// }).then(res => {
				// 	// console.log(res);
				// 	this.sid = res.sid;
				// });
            },
            // 又改成 一次定时器了 写的真烂...
            doCountDown() {
                if (this.theTime > 1) {
                    this.theTime--;
                    this.codeTimer = setTimeout(() => {
                        this.doCountDown();
                    }, 1000);
                } else {
                    this.codeSending = false;
                }
            },
            check() {
				if (this.emailcode == "") {
				    this.$toast(this.$t("sPay.PleaseEnterCorrectTransactionEmail"));
				    return false;
				}
                if (this.inputCode == "") {
                    this.$toast(this.$t("sPay.PleaseEnterCorrectVerificationCode"));
                    return false;
                }
                // if (this.loginpwd == "") {
                //   this.showAlert("请输入正确的登录密码");
                //   return false;
                // }
                
                // 判断原邮箱与新邮箱是否一致
                if (this.emailcode == this.email) {
                    this.$toast(this.$t("sPay.emailSame"));
                    return false;
                }

                return true;
            },
            onClickLeft() {
                this.$router.back(-1);
            },
        },
    };
</script>

<style scoped>
    .container,
    .content{
        height: 100%;
    }

    .body1Style {
        margin-top: 0.2rem;
        min-height: calc(100% - 0.2rem);
        border-top-left-radius: 0.32rem;
        border-top-right-radius: 0.32rem;
		padding: 0.3rem 0.4rem;
		font-family: PingFangSC-Regular;
    }

	.button {
		border: none !important;
		font-size: 0.24rem;
	}
    
    .email_old{
        margin-bottom: 0.4rem;
        font-size: .28rem;
    }
</style>
